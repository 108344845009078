import React, { useEffect, useRef, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { interval } from 'd3-timer';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';

let clipId = 0;
const getUniqueClipId = () => clipId++;

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

function AudioLevelIndicator({ audioTrack, color = 'white' }: { audioTrack?: AudioTrack; color?: string }) {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && SVGClipElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const maxY = 210; // the lowest volume display
      const minY = 30; // the highest volume display

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(maxY - minY, Math.max(0, values / length));

        var newY = maxY - volume;

        SVGClipElement?.setAttribute('y', String(newY));
      }, 100);

      return () => {
        SVGClipElement.setAttribute('y', String(maxY));
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  // Each instance of this component will need a unique HTML ID
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

  return isTrackEnabled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 240 240" data-test-audio-indicator>
      <defs>
        <clipPath id={clipPathId}>
          <rect ref={SVGRectRef} x="0" y="140" width="240" height="240" />
        </clipPath>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#FFFFFF" x="51" y="0" width="137" height="240" rx="8"></rect>
        <circle fill="#000000" cx="119.5" cy="77.5" r="50.5"></circle>
        <circle clipPath={`url(#${clipPathId})`} fill="#23BF6E" cx="119.5" cy="77.5" r="50.5"></circle>
        <circle fill="#FFFFFF" cx="119.5" cy="77.5" r="19.5"></circle>
        <circle fill="#000000" cx="119.5" cy="185.5" r="35.5"></circle>
        <circle clipPath={`url(#${clipPathId})`} fill="#23BF6E" cx="119.5" cy="185.5" r="35.5"></circle>
        <circle fill="#FFFFFF" cx="119.5" cy="185.5" r="13.5"></circle>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 240 240" data-test-audio-indicator>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#FFFFFF" x="51" y="0" width="137" height="240" rx="8"></rect>
        <circle fill="#000000" cx="119.5" cy="77.5" r="50.5"></circle>
        <circle fill="#FFFFFF" cx="119.5" cy="77.5" r="19.5"></circle>
        <circle fill="#000000" cx="119.5" cy="185.5" r="35.5"></circle>
        <circle fill="#FFFFFF" cx="119.5" cy="185.5" r="13.5"></circle>
      </g>
    </svg>
  );
}

export default React.memo(AudioLevelIndicator);
