import React from 'react';
import { makeStyles, Grid, Button, Theme } from '@material-ui/core';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  photoURL: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, photoURL, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName, photoURL).then(token => connect(token));
    // GAVIN: Turn on the audioTag
    //audioTag.play();
  };

  // Gavin, This controls the join screen
  return (
    <>
      <Grid container>
        <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
        <div className={classes.joinButtons}>
          <Button variant="contained" color="primary" data-cy-join-now onClick={handleJoin} disabled={disableButtons}>
            Join Now
          </Button>
        </div>
      </Grid>
    </>
  );
}
