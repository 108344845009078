import React, { SVGProps } from 'react';

export default function AirTokLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="60px" height="60px" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>AirTok</title>
      <defs>
        <path
          d="M242.008,420.8 C248.888,420.8 253.36,417.933333 255.424,412.2 L255.424,412.2 L263.68,389.496 L337.64,389.496 L345.896,412.2 C347.96,417.933333 352.432,420.8 359.312,420.8 L359.312,420.8 L422.952,420.8 C425.016,420.8 426.793333,420.054667 428.284,418.564 C429.774667,417.073333 430.52,415.296 430.52,413.232 C430.52,412.314667 430.405333,411.512 430.176,410.824 L430.176,410.824 L353.464,191.352 C352.546667,188.370667 350.826667,185.733333 348.304,183.44 C345.781333,181.146667 342.341333,180 337.984,180 L337.984,180 L263.336,180 C258.978667,180 255.538667,181.146667 253.016,183.44 C250.493333,185.733333 248.773333,188.370667 247.856,191.352 L247.856,191.352 L171.144,410.824 C170.914667,411.512 170.8,412.314667 170.8,413.232 C170.8,415.296 171.545333,417.073333 173.036,418.564 C174.526667,420.054667 176.304,420.8 178.368,420.8 L178.368,420.8 L242.008,420.8 Z M320.096,322.416 L281.224,322.416 L300.488,258.432 L320.096,322.416 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="AirTok" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <path
          stroke="#000000"
          strokeWidth="15"
          d="M338.045965,172.5 C344.960647,172.608176 349.740296,174.609768 353.349046,177.890449 C356.755239,180.986989 359.090505,184.545781 360.430472,188.520082 L360.586496,188.998989 L437.317851,408.526364 C437.845891,410.289091 438.02,411.671779 438.02,413.232 C438.02,417.341455 436.560611,420.893991 433.587301,423.867301 C430.713101,426.7415 427.297647,428.201025 423.361088,428.295133 L422.952,428.3 L359.257537,428.3 C354.072091,428.229114 349.950399,426.983586 346.704098,424.902624 C343.259372,422.694466 340.66273,419.514209 339.016525,415.217382 L338.843788,414.752729 L332.386796,396.996 L268.933204,396.996 L262.453038,414.816458 C260.698429,419.439087 257.980184,422.746033 254.488449,424.984325 C251.297227,427.02998 247.355662,428.213513 242.545987,428.295442 L242.008,428.3 L178.248836,428.3 C173.773203,428.171949 170.510158,426.64476 167.732699,423.867301 C164.759389,420.893991 163.3,417.341455 163.3,413.232 C163.3,411.532075 163.537069,410.038992 163.936934,408.739246 L164.049969,408.389553 L240.761166,188.919848 C242.278146,184.418236 244.61896,180.937716 247.970954,177.890449 C251.625343,174.568277 256.497736,172.607861 262.806905,172.50432 L263.336,172.5 L338.045965,172.5 Z M300.551507,284.236326 L291.314616,314.916 L309.953342,314.916 L300.551507,284.236326 Z"
        ></path>
        <g id="Group" mask="url(#mask-2)" stroke="#000000" strokeWidth="5">
          <g transform="translate(148.000000, 150.000000)" id="Rectangle">
            <rect fill="#FFFFFF" x="147.5" y="147.5" width="155" height="155"></rect>
            <rect fill="#F6FF00" x="-2.5" y="147.5" width="155" height="155"></rect>
            <rect fill="#60E0F4" x="147.5" y="-2.5" width="155" height="155"></rect>
            <rect fill="#EB5AD6" x="-2.5" y="-2.5" width="155" height="155"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
