import React from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

// GAVIN: Manage the user list

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 160,
      position: 'absolute',
      top: 200,
      right: 0,
      bottom: 0,
      left: 0,
      //padding: '2em',
      //overflowY: 'auto',
      //zIndex: 5,
      // [theme.breakpoints.down('sm')]: {
      //   gridArea: '2 / 1 / 3 / 3',
      //   overflowY: 'initial',
      //   overflowX: 'auto',
      //   display: 'flex',
      //   padding: '8px',
      // },
    },
    transparentBackground: {
      background: 'transparent',
    },
  })
);

export default function ParticipantList() {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  //if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <div
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
    >
      <Participant
        participant={localParticipant}
        isLocalParticipant={true}
        hideParticipant={localParticipant === mainParticipant}
      />
      {participants.map(participant => {
        //const isSelected = participant === selectedParticipant;
        // GAVIN: Always hide the mainParticipant
        /*const hideParticipant =
          participant === mainParticipant && participant !== screenShareParticipant && !isSelected;*/
        const hideParticipant = participant === mainParticipant;
        return (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={participant === selectedParticipant}
            onClick={() => setSelectedParticipant(participant)}
            hideParticipant={hideParticipant}
          />
        );
      })}
    </div>
  );
}
